import { query } from "../GraphQL";
import { useQuery } from "react-apollo";
import i18n from "../i18n";

export default function useGetBundled(subProducts: any, storeID: any) {
  const domain = localStorage.getItem("domain");

  const {
    data = {},
    loading,
    refetch,
  } = useQuery(query.GET_BUNDLED, {
    variables: {
      products: subProducts,
      storeID: storeID,
      domain: domain,
      language: i18n.language,
    },
  });

  const { getBundled } = data;

  const datos = getBundled
    ? getBundled.data
    : null;

  return { datos, loading, refetch };
}
