import { useState } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Avatar,
  Input,
  Tooltip,
  message,
  Modal,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { formaterPrice } from "../../Utils/FormaterPrice";
import { useQuery, useMutation } from "react-apollo";
import { query, mutations } from "../../GraphQL";
import AddProduct from "./add";
import EditProduct from "./EditProducto";
import "./index.css";
import i18n from "../../i18n";

const { Search } = Input;
const { confirm } = Modal;

export default function Products(props: any) {
  const [search, setsearch] = useState("");
  const [Loading, setLoading] = useState(false);
  const [visible, setvisible] = useState(false);
  const [dataDetails, setdataDetails] = useState(null);
  const [isd, setisd] = useState("");
  const [snoozedProduct] = useMutation(mutations.SNOOZED_PRODUCT);
  const { user, fromMenu, locationtype } = props;
  const [eliminarProducto] = useMutation(mutations.DELETE_PRODUCT);
  const domain = localStorage.getItem("domain");
  const queryToUse = search ? query.GET_PRODUCT_SEARCH : query.GET_PRODUCT;
  const { data, refetch, loading, error } = useQuery(queryToUse, {
    variables: { store:user, search: search, locationType: locationtype, domain: domain, language: i18n.language },
  });

  const onSearch = (value: string) => setsearch(value);

  const setDetalles = (prod: any) => {
    setdataDetails(prod);
    setvisible(true);
  };

  const eliminarProduct = (id: string) => {
    setisd(id);
    setLoading(true);
    eliminarProducto({ variables: { id: id } })
      .then((res) => {
        if (res.data.eliminarProducto.success) {
          setLoading(false);
          message.success(res.data.eliminarProducto.messages);
          refetch();
        } else {
          setLoading(false);
          message.warning(res.data.eliminarProducto.messages);
          refetch();
        }
      })
      .catch(() => {
        setLoading(false);
        message.error(i18n.t('addProduct:somethingWentWrong'));
      });
  };

  function showConfirm(id: string) {
    confirm({
      title: i18n.t('addProduct:confirmDeleteTitle'),
      icon: <ExclamationCircleOutlined />,
      content:
        i18n.t('addProduct:confirmDeleteContent'),
      okText: i18n.t('addProduct:deleteProduct'),
      cancelText: i18n.t('addProduct:cancel'),
      onOk() {
        eliminarProduct(id);
      },
      onCancel() {
        console.log(i18n.t('addProduct:cancel'));
      },
    });
  }

  const products =
  data && data.getNewProductoSearchStore
  ? data.getNewProductoSearchStore.data
  :
    data && data.getNewProductoaAllStore
      ? data.getNewProductoaAllStore.data
      : [];

  refetch();

  const snoozedProducts = (snoozed: boolean, id: string) => {
    snoozedProduct({ variables: { id: id, snoozed: snoozed } })
      .then((res) => {
        if (res.data.snoozedProduct.success) {
          message.success(i18n.t('addProduct:productMarkedAsOutOfStock'));
          refetch();
        } else {
          message.success(i18n.t('addProduct:somethingWentWrong'));
          refetch();
        }
      })
      .catch(() => {
        message.success(i18n.t('addProduct:somethingWentWrong'));
      });
  };

  const columns = [
    {
      key: "name",
      title: i18n.t('addProduct:name'),
      render: (data: any) => {
        return (
          <Space size="middle">
            <Avatar shape="square" size={50} src={data.imageUrl} />
            <h3 className="name_product">{data.name[i18n.language]}</h3>
          </Space>
        );
      },
    },
    {
      key: "price",
      title: i18n.t('addProduct:price2'),
      render: (data: any) => {
        return <p>{formaterPrice(data.price / 100, "", "")}</p>;
      },
    },

    {
      key: "price Local",
      title: i18n.t('addProduct:localPrice'),
      render: (data: any) => {
        return <p>{data.priceInHouse ? formaterPrice(data.priceInHouse / 100, "", "") : "0,0"}</p>;
      },
    },
    {
      key: "description",
      title: i18n.t('addProduct:description'),
      render: (data: any) => {
        return <p className="name_product">{data.description[i18n.language]}</p>;
      },
    },

    {
      key: "account",
      title: i18n.t('addProduct:availability'),
      render: (snoozed: any) =>
        snoozed.snoozed ? (
          <Tag color="red">{i18n.t('addProduct:outOfStock')}</Tag>
        ) : (
          <Tag color="lime">{i18n.t('addProduct:available')}</Tag>
        ),
    },
    

    {
      key: "account",
      title: i18n.t('addProduct:location'),
      render: (dats: any) => {
        return dats.locationType.map((ite: any, i:number)=> <Tag color="green" key={i}>{ite}</Tag>)
      }
    },

    {
      key: "account",
      title: i18n.t('addProduct:crossSelling'),
      render: (snoozed: any) =>
        !snoozed.related ? (
          <Tag color="red">{i18n.t('addProduct:no')}</Tag>
        ) : (
          <Tag color="lime">{i18n.t('addProduct:yes')}</Tag>
        ),
    },
    {
      key: "_id",
      title: i18n.t('addProduct:action'),
      render: (data: any) => (
        <Space size="middle">
          <Tooltip title={i18n.t('addProduct:editProduct')}>
            <Button
              icon={<EditOutlined />}
              type="primary"
              onClick={() => setDetalles(data)}
            />
          </Tooltip>
          <Tooltip title={i18n.t('addProduct:deleteProduct')}>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => showConfirm(data._id)}
              loading={isd === data._id && Loading}
            />
          </Tooltip>
          {data.snoozed ? (
            <Tooltip title={i18n.t('addProduct:notVisible')}>
              <Button
                type="dashed"
                icon={<EyeInvisibleOutlined />}
                onClick={() => snoozedProducts(false, data._id)}
              />
            </Tooltip>
          ) : (
            <Tooltip title={i18n.t('addProduct:visible')}>
              <Button
                type="dashed"
                icon={<EyeOutlined />}
                onClick={() => snoozedProducts(true, data._id)}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div style={{ width: "100%" }}>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",            
        marginBottom: 20,
        marginLeft: fromMenu ? 20 : 0,
      }}
    >
      <AddProduct store={user} refetch={refetch} />
      <Search
        placeholder={i18n.t('addProduct:searchProducts')}
        allowClear
        enterButton={i18n.t('addProduct:search')}
        size="large"
        onSearch={onSearch}
        style={{ width: 300 }}
      />
    </div>
  
    <Table columns={columns} dataSource={products} loading={loading} />
    
    {dataDetails ? (
      <EditProduct
        datas={dataDetails}
        refetch={refetch}
        visible={visible}
        setVisible={setvisible}
        user={user}
        setdataDetails={setdataDetails}
      />
    ) : null}
  </div>
  );
}
