import { query } from "../GraphQL";
import { useQuery } from "react-apollo";
import i18n from "../i18n";

export default function useGetModifield(subProducts: any, storeID: any) {
  const domain = localStorage.getItem("domain");

  const {
    data = {},
    loading,
    refetch,
  } = useQuery(query.GET_MODIFIELD, {
    variables: {
      products: subProducts,
      storeID: storeID,
      domain: domain,
      language: i18n.language,
    },
  });

  const { getModifieldGroup } = data;

  const datos = getModifieldGroup
    ? getModifieldGroup.data
    : null;

  return { datos, loading, refetch };
}
