// Función para añadir la cabecera de rol a las peticiones HTTP
const addRoleHeader = (headers: Headers) => {
  const token = localStorage.getItem("token");
  headers.append("authorization", JSON.stringify(token));
  headers.append("role", "Store");
  return headers;
};

// Wrapper para fetch
export const fetchWithRole = (url: string, options: RequestInit = {}) => {
  const newOptions = { ...options };
  newOptions.headers = addRoleHeader(new Headers(newOptions.headers));
  return fetch(url, newOptions);
};
