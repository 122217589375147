import { useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Tooltip, Button, Space, message, Modal } from "antd";
import { formaterPrice } from "../../../Utils/FormaterPrice";
import EditProduct from "./index";
import EdditBundled from "./EditBundled";
import { useMutation } from "react-apollo";
import { mutations } from "../../../GraphQL";
import "./index.css";
import i18n from "../../../i18n";
import { PRIMARY_COLOR } from "../../../Utils/Urls";

const { confirm } = Modal;

function Bundles(props: any) {
  const { bundled, currency, language, refetch } = props;

  const [Loading, setLoading] = useState(false);
  const [ids, setIds] = useState("");

  const [dataDetails, setdataDetails] = useState(null);
  const [visible, setvisible] = useState(false);

  const [dataBundled, setdataBundled] = useState(null);
  const [visibleBundled, setvisibleBundled] = useState(false);

  const editProductBundeld = (items: any) => {
    setdataDetails(items);
    setvisible(true);
  };

  const editBundeld = (items: any) => {
    setdataBundled(items);
    setvisibleBundled(true);
  };

  const [eliminarProducto] = useMutation(mutations.DELETE_PRODUCT);
  const [eliminarBundled] = useMutation(mutations.ELIMINAR_BUNDLED);

  const eliminarProduct = (id: string) => {
    setIds(id);
    setLoading(true);
    eliminarProducto({ variables: { id: id } })
      .then((res: any) => {
        if (res.data.eliminarProducto.success) {
          setLoading(false);
          message.success(res.data.eliminarProducto.messages);
          refetch();
        } else {
          setLoading(false);
          message.warning(res.data.eliminarProducto.messages);
          refetch();
        }
      })
      .catch(() => {
        setLoading(false);
        message.error(i18n.t('addProduct:somethingWentWrong'));
      });
  };

  const eliminarBundleds = (id: string) => {
    setIds(id);
    setLoading(true);
    eliminarBundled({ variables: { id: id } })
      .then((res: any) => {
        if (res.data.eliminarBundled.success) {
          setLoading(false);
          message.success(res.data.eliminarBundled.messages);
          refetch();
        } else {
          setLoading(false);
          message.warning(res.data.eliminarBundled.messages);
          refetch();
        }
      })
      .catch(() => {
        setLoading(false);
        message.error(i18n.t('addProduct:somethingWentWrong'));
      });
  };

  function showConfirm(id: string) {
    confirm({
      title: i18n.t('addProduct:confirmDeleteTitle'),
      icon: <ExclamationCircleOutlined />,
      content:
        i18n.t('addProduct:confirmDeleteContent'),
      okText: i18n.t('addProduct:deleteProduct'),
      cancelText: i18n.t('addProduct:cancel'),
      onOk() {
        eliminarProduct(id);
      },
      onCancel() {
        console.log(i18n.t('addProduct:cancel'));
      },
    });
  }

  function showConfirmBundled(id: string) {
    confirm({
      title: i18n.t('addProduct:deleteOptionsTitle'),
      icon: <ExclamationCircleOutlined />,
      content:
        i18n.t('addProduct:deleteOptionsContent'),
      okText: i18n.t('addProduct:deleteOptions'),
      cancelText: i18n.t('addProduct:cancel'),
      onOk() {
        eliminarBundleds(id);
      },
      onCancel() {
        console.log(i18n.t('addProduct:cancel'));
      },
    });
  }

  return (
    <div className="container_complementos">
      {bundled.map((item: any, i: any) => {
        const required = item.min > 0;
        return (
          <div className="complement" key={i}>
            <div className="complement__title">
              <div>
                <h4 style={{ marginLeft: 15 }}>{item.name[i18n.language]}</h4>
                <p style={{ marginLeft: 15, marginTop: 10 }}>
                  {item.min} {item.min > 1 ? i18n.t('addProduct:products') : i18n.t('addProduct:product')} {i18n.t('addProduct:toChoose')}{" "}
                  {required ? (
                    <span className="Requerido">{i18n.t('addProduct:required')}</span>
                  ) : null}
                </p>
              </div>
              <Space
                size="middle"
                style={{ marginLeft: "auto", marginRight: 10 }}
              >
                <Tooltip title={i18n.t('addProduct:editProduct')}>
                  <Button
                    icon={<EditOutlined />}
                    type="primary"
                    onClick={() => editBundeld(item)} 
                  />
                </Tooltip>

                {/* <Tooltip title={i18n.t('addProduct:deleteProduct')}>
                  <Button
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => showConfirmBundled(item._id)}
                    loading={ids === item._id && Loading}
                  />
                </Tooltip> */}
              </Space>
            </div>
            <div className="childerm">
              {item.Products.map((subProd: any, y: any) => {
                return (
                  <div key={y} className="list_chill" onClick={() => {}}>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                      }}
                    >
                      {subProd.imageUrl ? (
                        <img
                          src={subProd.imageUrl}
                          alt=""
                          style={{
                            width: 30,
                            height: 30,
                            objectFit: "cover",
                            objectPosition: "center",
                            borderRadius: 100,
                            marginRight: 10,
                          }}
                        />
                      ) : null}

                      <div>
                        <p
                          style={{
                            fontWeight: 300,
                            color: "gray",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          {subProd.name[i18n.language]} (× {subProd.multiMax}){" "}
                          {subProd.price > 0 ? (
                            <span style={{ color: PRIMARY_COLOR }}>
                              {`+ (${formaterPrice(
                                subProd.price / 100,
                                //@ts-ignore
                                language,
                                currency
                              )})`}
                            </span>
                          ) : null}
                        </p>
                        {subProd.recomended ? (
                          <span
                            style={{
                              color: PRIMARY_COLOR,
                              fontSize: 10,
                            }}
                          >
                            {i18n.t('addProduct:recommended')}
                          </span>
                        ) : null}
                        <br />
                        {subProd.snoozed ? (
                          <span className="no_stock">{i18n.t('addProduct:outOfStock')}</span>
                        ) : null}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Space size="middle">
                        {/* <Tooltip title={i18n.t('addProduct:editProduct')}>
                          <Button
                            icon={<EditOutlined />}
                            type="primary"
                            onClick={() => editProductBundeld(subProd)}
                          />
                        </Tooltip> */}

                        {/* <Tooltip title={i18n.t('addProduct:deleteProduct')}>
                          <Button
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => showConfirm(subProd._id)}
                            loading={ids === subProd._id && Loading}
                          />
                        </Tooltip> */}
                      </Space>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      {dataDetails ? (
        <EditProduct
          datas={dataDetails}
          refetch={refetch}
          visible={visible}
          setVisible={setvisible}
          bundled={true}
          setdataDetails={setdataDetails}
        />
      ) : null}

      {dataBundled ? (
        <EdditBundled
          datas={dataBundled}
          refetch={refetch}
          bundled={true}
          visible={visibleBundled}
          setVisible={setvisibleBundled}
          setdataDetails={setdataBundled}
        />
      ) : null}
    </div>
  );
}

export default Bundles;
