import { useState, useEffect } from "react";
import "./index.css";
import {
  message,
  Modal,
  Input,
  Tooltip,
  Upload,
  Switch,
  Spin,
  Button,
  InputNumber,
  Select,
  Tag,
} from "antd";
import { PlusCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { mutations } from "../../../GraphQL";
import { useMutation, Mutation } from "react-apollo";
import { DeleteOutlined } from "@ant-design/icons";
import { formaterPrice } from "../../../Utils/FormaterPrice";
import Bundles from "./Bundled";
import ModifielGroup from "./ModifieldGroup";
import AddNewBundles from "./AddBundled";
import AddNewModifielGroup from "./AddModifieldGroup";
import { LOCAL_API_URL } from "../../../config";
import Alergenos from "../alergenos";
import i18n from "../../../i18n";
import { PRIMARY_COLOR } from "../../../Utils/Urls";
import useGetBundled from "../../../Hooks/useGetBundled";
import useGetModifield from "../../../Hooks/useGetModifield";
import {fetchWithRole} from "../../../Utils/api";

const { TextArea } = Input;

const options = [{ value: "Delivery" }, { value: "Local" }];

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default function Eddit(props) {
  const domain = localStorage.getItem("domain");
  const { refetch, datas, visible, setVisible, setdataDetails, bundled } =
    props;

  const {
    datos: bundles,
    loading: loadingBundles,
    refetch: refetchBundle,
  } = useGetBundled(datas.subProducts, datas.location);

  const {
    datos: modifieldGroup,
    loading: loadingModifieldGroup,
    refetch: refetchModifieldGroup,
  } = useGetModifield(datas.subProducts, datas.location);

  const [isNew, setisNew] = useState(datas.new);
  const [isPopular, setisPopular] = useState(datas.popular);
  const [isOffert, setisOffert] = useState(datas.offert);
  const [title, settitle] = useState(datas.name[i18n.language]);
  const [ingredientes, setingredientes] = useState(
    datas.description[i18n.language]
  );
  const [price, setprice] = useState(datas.price);
  const [previus_Price, setprevius_Price] = useState(datas.previous_price);
  const [imagen, setimagen] = useState(datas.imageUrl);
  const [loadingImage, setloadingImage] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [priceLocal, setpriceLocal] = useState(datas.priceInHouse);
  const [previus_PriceLocal, setprevius_PriceLocal] = useState(
    datas.previous_priceInHouse
  );
  const [priceLocal1, setpriceLocal1] = useState(datas.priceInHouse1);
  const [previus_PriceLocal1, setprevius_PriceLocal1] = useState(
    datas.previous_priceInHouse1
  );
  const [recomended, setrecomended] = useState(datas.recomended);
  const [snoozed, setsnoozed] = useState(datas.snoozed);
  const [related, setrelated] = useState(datas.related);
  const [locationType, setLocationType] = useState(datas.locationType);
  const [alergenos, setAlergenos] = useState(datas.alergenos);
  const [label, setlabel] = useState(datas.label);
  const [label1, setlabel1] = useState(datas.label1);
  const [alergenosBackEnd, setAlergenosBackend] = useState([]);
  const [bundlesList, setBundlesList] = useState([]);
  const [modifieldGroupList, setModifieldGroupList] = useState([]);

  const [actualizarProduct] = useMutation(mutations.ACTUALIZAR_PRODUCT);

  const idInLocal = locationType?.filter((x) => x === "Local").length > 0;
  const idInDelivery = locationType?.filter((x) => x === "Delivery").length > 0;

  const [max, setmax] = useState(datas.max);
  const [min, setmin] = useState(datas.min);
  const [mulmax, setmulmax] = useState(datas.multiMax);
  const [mulmin, setmulmin] = useState(datas.multiply);

  const uploadButton = (
    <Tooltip title={i18n.t("addProduct:adPhoto")}>
      <div>
        <div className="ant-upload-text">
          {loadingImage ? (
            <LoadingOutlined style={{ fontSize: 30, color: PRIMARY_COLOR }} />
          ) : (
            <PlusCircleOutlined
              style={{ fontSize: 30, color: PRIMARY_COLOR }}
            />
          )}
          <p>{i18n.t("addProduct:addPhoto")}</p>
        </div>
      </div>
    </Tooltip>
  );

  const tagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="green"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const getAlergenos = async () => {
    const resp = await fetchWithRole(`${LOCAL_API_URL}/alergenos`);
    const data = await resp.json();
    if (data.success) {
      setAlergenosBackend(data.data);
    } else {
      setAlergenosBackend([]);
    }
  };

  useEffect(() => {
    getAlergenos();
  }, []);

  useEffect(() => {
    getAlergenos();
  }, [locationType]);

  const input = {
    _id: datas._id,
    name: title,
    description: ingredientes,
    account: datas.account,
    location: datas.location,
    productType: datas.productType,
    plu: datas.plu,
    sortOrder: datas.sortOrder,
    deliveryTax: datas.deliveryTax,
    takeawayTax: datas.takeawayTax,
    multiply: mulmin,
    multiMax: mulmax,
    posProductId: datas.posProductId,
    posProductCategoryId: datas.posProductCategoryId,
    // subProducts: datas.subProducts,
    subProducts: [],
    productTags: datas.productTags,
    posCategoryIds: datas.posCategoryIds,
    imageUrl: imagen,
    max: max,
    min: min,
    capacityUsages: datas.capacityUsages,
    parentId: datas.parentId,
    visible: datas.visible,
    snoozed: bundled ? snoozed : datas.snoozed,
    subProductSortOrder: [],
    recomended: bundled ? recomended : datas.recomended,
    quantity: datas.quantity,
    new: isNew,
    popular: isPopular,
    offert: isOffert,
    storeId: datas.storeId,
    related: related,
    locationType: locationType,
    alergenos: alergenos,
    priceInHouse: Number(priceLocal?.toFixed(0)),
    previous_priceInHouse: Number(previus_PriceLocal?.toFixed(0)),
    priceInHouse1: Number(priceLocal1?.toFixed(0)),
    previous_priceInHouse1: Number(previus_PriceLocal1?.toFixed(0)),
    previous_price: Number(previus_Price?.toFixed(0)),
    price: Number(price?.toFixed(0)),
    label: label,
    label1: label1,
  };

  const handleOk = () => {
    if (price === 0 && !bundled) {
      message.warning(i18n.t("addProduct:priceRequired"));
      return;
    }
    
   if(min < 1 && bundled){
      message.warning(i18n.t("addProduct:min<1"));
      return;
    }
    if(min > max){
      message.warning(i18n.t("addProduct:min>max"));
      return;
    }
    if(mulmin > mulmax){
      message.warning(i18n.t("addProduct:mulmin>mulmax"));
      return;
    }
    const allSubProducts = [...bundlesList, ...modifieldGroupList];
    input.subProducts = allSubProducts.map((x) => x._id);
    setConfirmLoading(true);
    setConfirmLoading(false);

    actualizarProduct({
      variables: {
        input: { data: input, language: i18n.language, domain: domain },
      },
    })
      .then((res) => {
        if (res.data.actualizarProduct.success) {
          refetch();
          setVisible(false);
          setdataDetails(null);
          setConfirmLoading(false);
          message.success(i18n.t("addProduct:productSuccessfullyUpdated"));
        } else {
          message.warning(i18n.t("addProduct:somethingWentWrong"));
          setConfirmLoading(false);
          refetch();
        }
      })
      .catch(() => {
        refetch();
        message.error(i18n.t("addProduct:somethingWentWrong"));
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    setVisible(false);
    setdataDetails(null);
  };

  function onChangeNew(checked) {
    setisNew(checked);
  }

  function onChangeRecomended(checked) {
    setrecomended(checked);
  }

  function onChangeSnoozed(checked) {
    setsnoozed(checked);
  }

  function onChangePopular(checked) {
    setisPopular(checked);
  }

  function onChangeOffert(checked) {
    setisOffert(checked);
  }

  function onChangeRelated(checked) {
    setrelated(checked);
  }

  function onChangeMax(value) {
    setmax(value);
  }

  function onChangeMin(value) {
    setmin(value);
  }

  function onChangemulMax(value) {
    setmulmax(value);
  }

  function onChangemulMin(value) {
    setmulmin(value);
    console.log("datas", datas);
  }

  const isOk = () => {
    if (title && locationType?.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const isOkLocal = () => {
    if (title && locationType?.length > 0) {
      if (idInDelivery) {
        return false;
      } else if (!idInDelivery) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const onchangeTitle = (e) => {
    e.preventDefault();
    settitle(e.target.value);
  };

  const onchangeIngre = (e) => {
    e.preventDefault();
    setingredientes(e.target.value);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  useEffect(() => {
    if (!loadingBundles) {
      setBundlesList(bundles);
    }
  }, [loadingBundles]);

  useEffect(() => {
    if (!loadingModifieldGroup) {
      setModifieldGroupList(modifieldGroup);
    }
  }, [loadingModifieldGroup]);

  return (
    <Modal
      title={i18n.t("addProduct:editProduct")}
      open={visible}
      onOk={handleOk}
      okText={i18n.t("addProduct:saveChanges")}
      cancelText={i18n.t("addProduct:cancel")}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      okButtonProps={{ disabled: idInLocal ? isOkLocal() : isOk() }}
    >
      <div className="content_modal">
        {!bundled ? (
          <div>
            <p>{i18n.t("addProduct:productPhoto")}</p>
            <div
              className="headder"
              style={{
                justifyContent: "center",
              }}
            >
              <div>
                <Mutation mutation={mutations.UPLOAD_FILE_AWS}>
                  {(singleUploadToAws) => (
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      customRequest={async (data) => {
                        setloadingImage(true);
                        let imgBlob = await getBase64(data.file);
                        singleUploadToAws({
                          variables: {
                            imgBlob,
                            domain: domain,
                            language: i18n.language,
                          },
                        })
                          .then((res) => {
                            setloadingImage(false);
                            setimagen(res.data.singleUploadToAws.data.Location);
                          })
                          .catch((error) => {
                            setloadingImage(false);
                            console.log("fs error: ", error);
                          });
                      }}
                    >
                      {imagen ? (
                        <Tooltip title="Haz click para cambiar">
                          <img
                            className="imagen_prod_add"
                            alt=""
                            src={imagen}
                          />
                        </Tooltip>
                      ) : null}

                      {!imagen ? uploadButton : null}
                    </Upload>
                  )}
                </Mutation>

                {imagen ? (
                  <Button
                    onClick={() => setimagen("")}
                    icon={<DeleteOutlined />}
                    type="primary"
                    danger
                    style={{ width: 200, marginTop: 10 }}
                  >
                    {i18n.t("addProduct:deleteImage")}
                  </Button>
                ) : null}
                <br />

                <Button
                  type="primary"
                  style={{ width: 200, marginTop: 10 }}
                  href="https://www.iloveimg.com/es/comprimir-imagen"
                  target="_blank"
                >
                  {i18n.t("addProduct:compressImage")}
                </Button>
              </div>
            </div>
          </div>
        ) : null}

        <div className="add_info">
          <h4>{i18n.t("addProduct:productInfo")}</h4>
          <p>
            {i18n.t("addProduct:productName")}{" "}
            <span style={{ color: "red" }}>*</span>
          </p>
          <Input
            placeholder={i18n.t("addProduct:productName")}
            onChange={onchangeTitle}
            defaultValue={title}
          />
          <br />
          <br />
          {bundled ? null : (
            <>
              <p>{i18n.t("addProduct:ingredientsOrDescription")}</p>
              <TextArea
                rows={4}
                // showCount
                // maxLength={300}
                defaultValue={ingredientes}
                onChange={onchangeIngre}
                placeholder={i18n.t(
                  "addProduct:ingredientsOrDescriptionProduct"
                )}
              />
            </>
          )}

          {idInDelivery ? (
            <div className="btn_contents">
              <div style={{ width: "100%" }}>
                <p>
                  {i18n.t("addProduct:salePrice")}{" "}
                  <span style={{ color: "red" }}>*</span>{" "}
                </p>
                <p style={{ color: PRIMARY_COLOR, fontWeight: "700" }}>
                  {formaterPrice(datas.price / 100, "", "")}{" "}
                  {i18n.t("addProduct:actualPrice")}
                </p>
                <InputNumber
                  style={{ width: bundled ? "48%" : "98%", marginTop: 8 }}
                  value={price && price / 100}
                  placeholder={i18n.t("addProduct:newSalePrice")}
                  min={0}
                  type="number"
                  onChange={(value) => setprice(value * 100)}
                />
              </div>

              {!bundled ? (
                <div style={{ width: "100%" }}>
                  <p>{i18n.t("addProduct:regularPrice")}r</p>
                  <p style={{ color: PRIMARY_COLOR, fontWeight: "700" }}>
                    {formaterPrice(
                      datas.previous_price
                        ? datas.previous_price / 100
                        : 0 / 100,
                      "",
                      ""
                    )}{" "}
                    {i18n.t("addProduct:actualPrice")}
                  </p>
                  <InputNumber
                    style={{ width: "100%", marginTop: 8 }}
                    min={0}
                    type="number"
                    value={previus_Price && previus_Price / 100}
                    placeholder={i18n.t("addProduct:newRegularPrice")}
                    onChange={(value) => setprevius_Price(value * 100)}
                  />
                </div>
              ) : null}
            </div>
          ) : null}

          {bundled ? (
            <div>
              <div className="btn_contents">
                <div>
                  <p>{i18n.t("addProduct:minimum")}</p>
                  <InputNumber
                    min={bundled ? 1 : 0}
                    max={100}
                    value={min}
                    style={{ width: "80%" }}
                    placeholder={i18n.t("addProduct:minimum")}
                    type="number"
                    onChange={onChangeMin}
                  />
                </div>
                <div>
                  <p>{i18n.t("addProduct:maximum")}</p>
                  <InputNumber
                    min={1}
                    max={100}
                    value={max}
                    style={{ width: "80%" }}
                    placeholder={i18n.t("addProduct:maximum")}
                    type="number"
                    onChange={onChangeMax}
                  />
                </div>
              </div>

              <div className="btn_contents">
                <div>
                  <p>{i18n.t("addProduct:minimumMultiplicable")}</p>
                  <InputNumber
                    min={bundled ? 1 : 0}
                    max={100}
                    value={mulmin}
                    style={{ width: "80%" }}
                    placeholder={i18n.t("addProduct:minimumMultiplicable")}
                    onChange={onChangemulMin}
                  />
                </div>
                <div>
                  <p>{i18n.t("addProduct:maximumMultiplicable")}</p>
                  <InputNumber
                    min={1}
                    max={100}
                    value={mulmax}
                    type="number"
                    style={{ width: "80%" }}
                    placeholder={i18n.t("addProduct:maximumMultiplicable")}
                    onChange={onChangemulMax}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {idInLocal ? (
            <div className="btn_contents">
              <div style={{ width: "100%" }}>
                <p>
                  {i18n.t("addProduct:localSalePrice")}{" "}
                  <span style={{ color: "red" }}>*</span>{" "}
                </p>
                <p style={{ color: PRIMARY_COLOR, fontWeight: "700" }}>
                  {formaterPrice(datas.priceInHouse / 100, "", "")}{" "}
                  {i18n.t("addProduct:actualLocalPrice")}
                </p>
                <div>
                  <InputNumber
                    style={{ width: bundled ? "48%" : "98%", marginTop: 8 }}
                    placeholder={i18n.t("addProduct:newLocalSalePrice")}
                    min={0}
                    value={priceLocal && priceLocal / 100}
                    type="number"
                    onChange={(value) => setpriceLocal(value * 100)}
                  />
                  <div style={{ width: "100%", marginTop: 10 }}>
                    <p style={{ margin: 0 }}>Etiqueta de precio</p>
                    <Select
                      defaultValue={label}
                      style={{ width: bundled ? "48%" : "98%" }}
                      onChange={setlabel}
                      placeholder={i18n.t("addProduct:priceTag")}
                      options={[
                        {
                          value: "Ración",
                          label: "Ración",
                        },
                        {
                          value: "1/2 Ración",
                          label: "1/2 Ración",
                        },
                        {
                          value: "Unidad",
                          label: "Unidad",
                        },

                        {
                          value: "Copa",
                          label: "Copa",
                        },

                        {
                          value: "Botella",
                          label: "Botella",
                        },
                        {
                          value: "Botellin",
                          label: "Botellin",
                        },

                        {
                          value: "Lata",
                          label: "Lata",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              {!bundled ? (
                <div style={{ width: "100%" }}>
                  <p>{i18n.t("addProduct:regularLocalPrice")}</p>
                  <p style={{ color: PRIMARY_COLOR, fontWeight: "700" }}>
                    {formaterPrice(datas.previous_priceInHouse / 100, "", "")}{" "}
                    {i18n.t("addProduct:actualLocalPrice")}
                  </p>
                  <InputNumber
                    style={{ width: "100%", marginTop: 8 }}
                    min={0}
                    type="number"
                    value={previus_PriceLocal && previus_PriceLocal / 100}
                    placeholder={i18n.t("addProduct:newRegularPrice")}
                    onChange={(value) => setprevius_PriceLocal(value * 100)}
                  />
                  <div style={{ height: 64 }} />
                </div>
              ) : null}
            </div>
          ) : null}

          {idInLocal ? (
            <div className="btn_contents">
              <div style={{ width: "100%" }}>
                <p>{i18n.t("addProduct:secondLocalSalePrice")} </p>
                <p style={{ color: PRIMARY_COLOR, fontWeight: "700" }}>
                  {formaterPrice(datas.priceInHouse1 / 100, "", "")}{" "}
                  {i18n.t("addProduct:actualLocalPrice")}
                </p>
                <div>
                  <InputNumber
                    style={{ width: bundled ? "48%" : "98%", marginTop: 8 }}
                    placeholder={i18n.t("addProduct:newLocalSalePrice")}
                    min={0}
                    type="number"
                    onChange={(value) => setpriceLocal1(value * 100)}
                  />
                  <div style={{ width: "100%", marginTop: 10 }}>
                    <p style={{ margin: 0 }}>
                      {i18n.t("addProduct:priceTag")}o
                    </p>
                    <Select
                      defaultValue={label1}
                      style={{ width: "98%" }}
                      onChange={setlabel1}
                      placeholder={i18n.t("addProduct:priceTag")}
                      options={[
                        {
                          value: "Ración",
                          label: "Ración",
                        },
                        {
                          value: "1/2 Ración",
                          label: "1/2 Ración",
                        },
                        {
                          value: "Unidad",
                          label: "Unidad",
                        },

                        {
                          value: "Copa",
                          label: "Copa",
                        },

                        {
                          value: "Botella",
                          label: "Botella",
                        },
                        {
                          value: "Botellin",
                          label: "Botellin",
                        },

                        {
                          value: "Lata",
                          label: "Lata",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              {!bundled ? (
                <div style={{ width: "100%" }}>
                  <p>{i18n.t("addProduct:secondLocalRegularPrice")}</p>
                  <p style={{ color: PRIMARY_COLOR, fontWeight: "700" }}>
                    {formaterPrice(datas.previous_priceInHouse1 / 100, "", "")}{" "}
                    {i18n.t("addProduct:actualLocalPrice")}
                  </p>
                  <div>
                    <InputNumber
                      style={{ width: "100%", marginTop: 8 }}
                      min={0}
                      type="number"
                      placeholder={i18n.t("addProduct:newRegularPrice")}
                      onChange={(value) => setprevius_PriceLocal1(value * 100)}
                    />
                    <div style={{ height: 64 }} />
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}

          {bundled ? (
            <div className="btn_contents">
              <div className="btn_item popu">
                <p>{i18n.t("addProduct:recommended")}</p>
                <Switch
                  defaultChecked={recomended}
                  onChange={onChangeRecomended}
                  className={recomended ? "popu" : "popu"}
                />
              </div>
              <div className="btn_item new">
                <p>{i18n.t("addProduct:outOfStock")}</p>
                <Switch
                  defaultChecked={snoozed}
                  onChange={onChangeSnoozed}
                  className={snoozed ? "New" : "NoNew"}
                />
              </div>
            </div>
          ) : (
            <div className="btn_contents">
              <div className="btn_item new">
                <p>{i18n.t("addProduct:new")}</p>
                <Switch
                  defaultChecked={isNew}
                  onChange={onChangeNew}
                  className={isNew ? "New" : "NoNew"}
                />
              </div>
              <div className="btn_item offert">
                <p>{i18n.t("addProduct:offer")}</p>
                <Switch
                  defaultChecked={isOffert}
                  onChange={onChangeOffert}
                  className={isOffert ? "Off" : "NoOff"}
                />
              </div>
              <div className="btn_item popu">
                <p>{i18n.t("addProduct:popular")}</p>
                <Switch defaultChecked={isPopular} onChange={onChangePopular} />
              </div>
            </div>
          )}
        </div>

        {!bundled ? (
          <div className="btn_item popu" style={{ marginTop: 20 }}>
            <p style={{ marginTop: 13 }}>{i18n.t("addProduct:crossSelling")}</p>
            <Switch defaultChecked={related} onChange={onChangeRelated} />
          </div>
        ) : null}
        <div style={{ width: "100%", marginTop: 30 }}>
          <p style={{ margin: 0 }}>{i18n.t("addProduct:location")}</p>
          <Select
            mode="multiple"
            showArrow
            tagRender={tagRender}
            defaultValue={locationType}
            style={{ width: "100%" }}
            options={options}
            onChange={(values) => {
              getAlergenos();
              setLocationType(values);
            }}
          />
        </div>

        {(idInLocal || idInDelivery) && !bundled ? (
          <div style={{ marginTop: 20 }}>
            <p style={{ margin: 0 }}>Alérgenos</p>
            <Alergenos
              data={alergenosBackEnd}
              alergenos={alergenos}
              setAlergenos={setAlergenos}
            />
          </div>
        ) : null}

        {bundled ? null : (
          <div style={{ marginBottom: 40, marginTop: 40 }}>
            <h3>
              {i18n.t("addProduct:mandatoryOptions")}{" "}
              <span style={{ color: "red" }}>*</span>
            </h3>
            <AddNewBundles
              datas={datas}
              bundlesList={bundlesList}
              setBundlesList={setBundlesList}
              refetch={refetchBundle}
              refetching={refetch}
            />
          </div>
        )}

        <div>
          {loadingBundles ? (
            <div
              style={{
                width: "100%",
                height: "70%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                marginTop: 60,
              }}
            >
              <Spin indicator={antIcon} />
            </div>
          ) : (
            <Bundles bundled={bundlesList} refetch={refetchBundle} />
          )}
        </div>

        {bundled ? null : (
          <div style={{ marginBottom: 40, marginTop: 40 }}>
            <h3>{i18n.t("addProduct:optionalModifiers")}</h3>
            <AddNewModifielGroup
              datas={datas}
              modifieldGroupList={modifieldGroupList}
              setModifieldGroupList={setModifieldGroupList}
              refetch={refetchModifieldGroup}
              refetching={refetch}
            />
          </div>
        )}
        {loadingModifieldGroup ? (
          <div
            style={{
              width: "100%",
              height: "70%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              marginTop: 60,
            }}
          >
            <Spin indicator={antIcon} />
          </div>
        ) : (
          <ModifielGroup
            modifierGroups={modifieldGroupList}
            loading={loadingModifieldGroup}
            refetch={refetchModifieldGroup}
            datas={datas}
          />
        )}
      </div>
    </Modal>
  );
}
