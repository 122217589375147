import React, { useState, useEffect } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { LOCAL_API_URL } from "../../config";
import arrayMove from "array-move";
import "./index.css";
import Products from "./products";
import AddCategory from "./AddCategory/add";
import DeleteCategory from "./DeleteCategory";
import { Skeleton, Button } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import i18n from "../../i18n";
import {fetchWithRole} from "../../Utils/api";

const SortableComponent = (props) => {
  const { user, menu, inHouse, store } = props;
  const [tasks, setTasks] = useState([]);
  const [loadin, setloadin] = useState(false);
  const domain = localStorage.getItem("domain");

  const getData = async () => {
    setloadin(true);
    const res = await fetchWithRole(
      `${LOCAL_API_URL}/menu?idstore=${user}&inHouse=${inHouse}`
    );
    const tasks = await res.json();
    tasks.sort((a, b) =>
      a.sorting > b.sorting ? 1 : b.sorting > a.sorting ? -1 : 0
    );
    setTasks(tasks);
    setTimeout(() => {
      setloadin(false);
    }, 2000);
  };

  useEffect(() => {
    getData();
  }, []);

  const gotToLink = () => {
    const url = inHouse
      ? `https://${
          domain === "wilbby" ? domain + ".com" : domain
        }/carta-digital/${store.slug}`
      : `https://${domain === "wilbby" ? domain + ".com" : domain}/store/${store.slug}`;
    window.open(
      url,
      "_blank" // <- This is what makes it open in a new window.
    );
  };

  const SortableItem = SortableElement(({ value }) => {
    return (
      <>
        {loadin ? (
          <Skeleton active />
        ) : (
          <>
            <li
              className="list-group-item"
              style={{ borderColor: value.snoozed ? "#F5365C" : "gainsboro" }}
            >
              <div>
                <h1>{value.name[i18n.language]}</h1>
                <p>{value.description[i18n.language]}</p>
                {value.snoozed ? <p>{i18n.t('menu:hiddenCategory')}</p> : null}
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  className="imagen_prod_add_category"
                  src={value.imageUrl}
                  alt=""
                />
                <DeleteCategory id={value._id} getData={getData} data={value} />
              </div>
            </li>
            <Products
              produts={value.products}
              user={user}
              titleCat={value.name[i18n.language]}
              getData={getData}
              dataCategory={value}
              inHouse={inHouse}
            />
          </>
        )}
      </>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul className="list-group">
        <div>
          <Button
            type="link"
            size="large"
            icon={<LinkOutlined />}
            onClick={gotToLink}
          >
            {i18n.t('menu:seeMenu')}
          </Button>
          <AddCategory
            user={user}
            getData={getData}
            menu={menu}
            inHouse={inHouse}
          />
        </div>
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} value={value} />
        ))}
      </ul>
    );
  });

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    let tasksCopy = [...tasks];
    tasksCopy = arrayMove(tasksCopy, oldIndex, newIndex);
    setTasks(tasksCopy);
    const tasksIds = tasksCopy.map((t) => t._id);
    await fetchWithRole(`${LOCAL_API_URL}/menu`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tasksIds),
    });
  };

  return <SortableList items={tasks} onSortEnd={onSortEnd} />;
};

function Menu(props) {
  const { user, menu, inHouse, store } = props;

  return (
    <div className="container">
      <div className="row">
        <div>
          <h1>{i18n.t('menu:categories')}</h1>
          <SortableComponent
            user={user}
            menu={menu}
            inHouse={inHouse}
            store={store}
          />
        </div>
      </div>
    </div>
  );
}

export default Menu;
