import React, { useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Space, Tooltip, Button, message, Spin, Modal } from "antd";
import { formaterPrice } from "../../../Utils/FormaterPrice";
import EditModifieldGroup from "./EditModifielGroup";
import EditModifield from "./EdditModifield";
import { useMutation } from "react-apollo";
import { mutations } from "../../../GraphQL";
import "./index.css";
import i18n from "../../../i18n";
import { PRIMARY_COLOR } from "../../../Utils/Urls";

const { confirm } = Modal;

function ModifielGroup(props: any) {
  const { currency, language, modifierGroups, loading, refetch } = props;

  const [Loading, setLoading] = useState(false);
  const [ids, setIds] = useState("");

  const [dataModifield, setDataModifield] = useState(null);
  const [visible, setvisible] = useState(false);

  const [dataEditModifield, setdataEditModifield] = useState(null);
  const [visibleModifield, setvisibleModifield] = useState(false);

  const openEditModifieldGroup = (items: any) => {
    setDataModifield(items);
    setvisible(true);
  };

  const openEditModifield = (items: any) => {
    setdataEditModifield(items);
    setvisibleModifield(true);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  const [eliminarModifield] = useMutation(mutations.DELETE_MODIFIELD);
  const [eliminarmodifierGroups] = useMutation(mutations.DELETE_MODIFIELDGROUP);

  const eliminarModifields = (id: string) => {
    setIds(id);
    setLoading(true);
    eliminarModifield({ variables: { id: id } })
      .then((res: any) => {
        if (res.data.eliminarModifield.success) {
          setLoading(false);
          message.success(res.data.eliminarModifield.messages);
          refetch();
        } else {
          setLoading(false);
          message.warning(res.data.eliminarModifield.messages);
          refetch();
        }
      })
      .catch(() => {
        setLoading(false);
        message.error(i18n.t("addProduct:somethingWentWrong"));
      });
  };

  const eliminarModifieldGroups = (id: string) => {
    setIds(id);
    setLoading(true);
    eliminarmodifierGroups({ variables: { id: id } })
      .then((res: any) => {
        if (res.data.eliminarmodifierGroups.success) {
          setLoading(false);
          message.success(res.data.eliminarmodifierGroups.messages);
          refetch();
        } else {
          setLoading(false);
          message.warning(res.data.eliminarmodifierGroups.messages);
          refetch();
        }
      })
      .catch(() => {
        setLoading(false);
        message.error(i18n.t("addProduct:somethingWentWrong"));
      });
  };

  function showConfirm(id: string) {
    confirm({
      title: i18n.t("addProduct:confirmDeleteTitle2"),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t("addProduct:confirmDeleteContent"),
      okText: i18n.t("addProduct:deleteModifier"),
      cancelText: i18n.t("addProduct:cancel"),
      onOk() {
        eliminarModifields(id);
      },
      onCancel() {
        console.log(i18n.t("addProduct:cancel"));
      },
    });
  }

  function showConfirmModifieldGroups(id: string) {
    confirm({
      title: i18n.t("addProduct:confirmDeleteTitle2"),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t("addProduct:confirmDeleteContent"),
      okText: i18n.t("addProduct:deleteModifier"),
      cancelText: i18n.t("addProduct:cancel"),
      onOk() {
        eliminarModifieldGroups(id);
      },
      onCancel() {
        console.log(i18n.t("addProduct:cancel"));
      },
    });
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "70%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            marginTop: 60,
          }}
        >
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <div className="container_complementos">
          {modifierGroups.map((item: any, i: any) => {
            return (
              <div className="complement" key={i}>
                <div className="complement__title">
                  <div>
                    <h4 style={{ marginLeft: 15 }}>
                      {item.name[i18n.language]}
                    </h4>
                    <p style={{ marginLeft: 15 }}>
                      {i18n.t("addProduct:choose")} {item.min}{" "}
                      {item.min > 1
                        ? i18n.t("addProduct:options")
                        : i18n.t("addProduct:option")}
                    </p>
                  </div>

                  <Space
                    size="middle"
                    style={{ marginLeft: "auto", marginRight: 10 }}
                  >
                    <Tooltip title={i18n.t("addProduct:editModifier")}>
                      <Button
                        icon={<EditOutlined />}
                        type="primary"
                        onClick={() => openEditModifieldGroup(item)}
                        loading={ids === item._id && Loading}
                      />
                    </Tooltip>

                    {/* <Tooltip title={i18n.t("addProduct:deleteModifier")}>
                      <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => showConfirmModifieldGroups(item._id)}
                        loading={ids === item._id && Loading}
                      />
                    </Tooltip> */}
                  </Space>
                </div>
                <div className="childerm">
                  {item.Modifiers.map((subProd: any, y: any) => {
                    const ifselected = false;
                    return (
                      <div key={y}>
                        <div className="list_chill" onClick={() => {}}>
                          <div>
                            <p
                              style={{
                                fontWeight: ifselected ? 700 : 300,
                                color: ifselected ? "black" : "gray",
                              }}
                            >
                              {subProd.name[i18n.language]} (×{" "}
                              {subProd.multiMax}){" "}
                              {subProd.price > 0 ? (
                                <span
                                  style={{ color: PRIMARY_COLOR }}
                                >{`+ (${formaterPrice(
                                  subProd.price / 100,
                                  //@ts-ignore
                                  language,
                                  currency
                                )})`}</span>
                              ) : null}
                            </p>
                            {subProd.recomended ? (
                              <span
                                style={{
                                  color: PRIMARY_COLOR,
                                  fontSize: 10,
                                  lineHeight: 0,
                                }}
                              >
                                {i18n.t("addProduct:recommended")}
                              </span>
                            ) : null}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Space size="middle">
                              {/* <Tooltip
                                title={i18n.t("addProduct:editModifier")}
                              >
                                <Button
                                  icon={<EditOutlined />}
                                  type="primary"
                                  onClick={() => openEditModifield(subProd)}
                                  loading={ids === item._id && Loading}
                                />
                              </Tooltip> */}

                              {/* <Tooltip
                                title={i18n.t("addProduct:deleteModifier")}
                              >
                                <Button
                                  danger
                                  icon={<DeleteOutlined />}
                                  onClick={() => showConfirm(subProd._id)}
                                  loading={ids === item._id && Loading}
                                />
                              </Tooltip> */}
                            </Space>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}

          {dataEditModifield ? (
            <EditModifield
              datas={dataEditModifield}
              refetch={refetch}
              visible={visibleModifield}
              setVisible={setvisibleModifield}
              bundled={true}
              setdataDetails={setdataEditModifield}
            />
          ) : null}

          {dataModifield ? (
            <EditModifieldGroup
              datas={dataModifield}
              refetch={refetch}
              bundled={true}
              visible={visible}
              setVisible={setvisible}
              setdataDetails={setDataModifield}
            />
          ) : null}
        </div>
      )}
    </>
  );
}

export default ModifielGroup;
