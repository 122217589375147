import { useState } from "react";
import { FontColorsOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Modal, message, Button } from "antd";
import { useQuery, useMutation } from "react-apollo";
import { query, mutations } from "../../../GraphQL";
import CratedBundled from "./CreateBundled";
import { formaterPrice } from "../../../Utils/FormaterPrice";
import "./index.css";
import i18n from "../../../i18n";
import { PRIMARY_COLOR } from "../../../Utils/Urls";

export default function AddProduct(props: any) {
  const { datas, refetch, refetching, setBundlesList, bundlesList } = props;
  const [visible, setVisible] = useState(false);
  const [product, setproducts] = useState(datas.subProducts);
  const [actualizarProduct] = useMutation(mutations.ACTUALIZAR_PRODUCT);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const domain = localStorage.getItem("domain");

  const response = useQuery(query.GET_BUNDLED_STORE, {
    variables: { storeID: localStorage.getItem("id"), domain: domain, language: i18n.language},
  });

  const products =
    response && response.data && response.data.getBundledStore
      ? response.data.getBundledStore.data
      : [];

  const deletedItem = (item: any) => {
    setBundlesList(bundlesList.filter((x: any) => x._id !== item._id))
    var i = product.findIndex((x: any) => x === item._id);
    if (i !== -1) {
      product.splice(i, 1);
      setproducts(product.concat());
    }
  };

  const SelectProduct = (item: any) => {
    setBundlesList([...bundlesList, item])
    setproducts(product.concat(item._id));
  };

  const input = {
    _id: datas._id,
    name: datas.name[i18n.language] ? datas.name[i18n.language] : datas.name,
    description: datas.description[i18n.language] ? datas.description[i18n.language] : "",
    account: datas.account,
    location: datas.location,
    productType: datas.productType,
    plu: datas.plu,
    price: datas.price,
    sortOrder: datas.sortOrder,
    deliveryTax: datas.deliveryTax,
    takeawayTax: datas.takeawayTax,
    multiply: datas.multiply,
    multiMax: datas.multiMax,
    posProductId: datas.posProductId,
    posProductCategoryId: datas.posProductCategoryId,
    subProducts: product,
    productTags: datas.productTags,
    posCategoryIds: datas.posCategoryIds,
    imageUrl: datas.imageUrl,
    max: datas.max,
    min: datas.min,
    capacityUsages: datas.capacityUsages,
    parentId: datas.parentId,
    visible: datas.visible,
    snoozed: datas.snoozed,
    subProductSortOrder: [],
    recomended: datas.recomended,
    quantity: datas.quantity,
    new: datas.new,
    popular: datas.popular,
    offert: datas.offert,
    previous_price: datas.previous_price,
    storeId: datas.storeId,
  };

  const handleOk = () => {
    setConfirmLoading(true);
    actualizarProduct({ variables: { input: { data: input, language: i18n.language,domain: domain } } })
      .then((res) => {
        if (res.data.actualizarProduct.success) {
          setConfirmLoading(false);
          message.success(i18n.t("addProduct:optionSuccessfullyAdded"));
          response.refetch();
          refetch();
          refetching();
        } else {
          setConfirmLoading(false);
          message.success(i18n.t("addProduct:somethingWentWrong"));
          response.refetch();
          refetch();
          refetching();
        }
        setVisible(false)
      })
      .catch(() => {
        setConfirmLoading(false);
        message.success(i18n.t("addProduct:somethingWentWrong"));
      });
  };

  return (
    <div>
      <div className="add_cont_bundled" onClick={() => setVisible(true)}>
        <PlusCircleOutlined style={{ fontSize: 24, color: PRIMARY_COLOR }} />
        <p style={{ margin: 0, padding: 0, color: PRIMARY_COLOR }}>
          {i18n.t("addProduct:addAndEditMandatoryModifiers")}
        </p>
      </div>
      <Modal
        title={i18n.t("addProduct:addModifiers")}
        open={visible}
        onOk={handleOk}
        okText={i18n.t("addProduct:saveChanges")}
        cancelText={i18n.t("addProduct:cancel")}
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        okButtonProps={{ disabled: false }}
      >
        <div style={{ margin: 20 }}>
          <Button
            onClick={() => setVisibleAdd(true)}
            type="dashed"
            style={{ width: "100%", height: 50, marginBottom: 20 }}
          >
            {i18n.t("addProduct:createMandatoryModifier")}
          </Button>
          <CratedBundled
            refetch={response && response.refetch}
            visible={visibleAdd}
            setVisible={setVisibleAdd}
          />
          {products &&
            products.map((item: any, i: any) => {
              const adds =
                product && product.filter((p: any) => p === item._id).length > 0;

              return (
                <div key={i} className="bundled_item">
                  <div
                    className="bundled_item_children"
                    style={{ backgroundColor: adds ? PRIMARY_COLOR : "#eeeeee" }}
                    onClick={() =>
                      adds ? deletedItem(item) : SelectProduct(item)
                    }
                  >
                    <h3 style={{ fontWeight: "bold", color: adds ? "white" : "black" }}>{item.name[i18n.language]}</h3>
                    <span style={{ color: adds ? "white" : "black" }} >
                      {item.min} {item.min > 1 ? i18n.t("addProduct:products") : i18n.t("addProduct:product")} {i18n.t("addProduct:toChoose")}{" "}
                    </span>{" "}
                    - <span  style={{ color: adds ? "white" : "black" }}>{i18n.t("addProduct:required")}</span>
                  </div>
                  {item.Products.map((subItem: any, i: any) => {
                    return (
                      <div key={i} className="bundled_item_item">
                        - {subItem.name[i18n.language]} (× {subItem.multiMax}){" "}
                        {subItem.price > 0
                          ? `  +${formaterPrice(subItem.price / 100, "", "")}`
                          : null}
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </Modal>
    </div>
  );
}
