import { Button, Modal, Tag } from "antd";
import React, { useState } from "react";
import EditHour from "./Edit";
import i18n from "../../../i18n";

export default function HorarioReserva({
  visible,
  handleCancel,
  user,
  refetch,
}: any) {
  const [visibleEdit, setvisibleEdit] = useState(false);
  const [title, setTiele] = useState("");
  const [hour, setHours] = useState<any>(null);

  const onOpenModal = (tit: string, hours: any) => {
    setvisibleEdit(true);
    setTiele(tit);
    setHours(hours);
  };

  return (
    <Modal
      title={i18n.t('reservas:editBookingSchedule')}
      open={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <div>
        <h2>{i18n.t('reservas:schedule')}</h2>

        <div style={{ marginBottom: 15 }}>
          <h3>{i18n.t('reservas:monday')}</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {user.reservationScheduled[i18n.t('reservas:monday', {lng: "es"})].length === 0 ? (
              <Tag color="red">{i18n.t('reservas:close')}</Tag>
            ) : (
              <div>
                {user.reservationScheduled[i18n.t('reservas:monday', {lng: "es"})].map(
                  (item: any, i: number) => {
                    return (
                      <p key={i} style={{ color: "gray", margin: 0 }}>
                        {item.star}:00 {i18n.t('reservas:to')} {item.end}:00
                      </p>
                    );
                  }
                )}
              </div>
            )}
            <div>
              <Button
                type="primary"
                onClick={() =>
                  onOpenModal(i18n.t('reservas:monday', {lng: "es"}), user.reservationScheduled[i18n.t('reservas:monday', {lng: "es"})])
                }
              >
                {i18n.t('reservas:editMonday')}
              </Button>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: 15 }}>
          <h3>{i18n.t('reservas:tuesday')}</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {user.reservationScheduled[i18n.t('reservas:tuesday', {lng: "es"})].length === 0 ? (
              <Tag color="red">{i18n.t('reservas:closed')}</Tag>
            ) : (
              <div>
                {user.reservationScheduled[i18n.t('reservas:tuesday', {lng: "es"})].map(
                  (item: any, i: number) => {
                    return (
                      <p key={i} style={{ color: "gray", margin: 0 }}>
                        {item.star}:00 {i18n.t('reservas:to')} {item.end}:00
                      </p>
                    );
                  }
                )}
              </div>
            )}
            <div>
              <Button
                type="primary"
                onClick={() =>
                  onOpenModal(i18n.t('reservas:tuesday'), user.reservationScheduled[i18n.t('reservas:tuesday', {lng: "es"})])
                }
              >
                {i18n.t('reservas:editTuesday')}
              </Button>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: 15 }}>
          <h3>{i18n.t('reservas:wednesday')}</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {user.reservationScheduled[i18n.t('reservas:wednesday', {lng: "es"})].length === 0 ? (
              <Tag color="red">{i18n.t('reservas:closed')}</Tag>
            ) : (
              <div>
                {user.reservationScheduled[i18n.t('reservas:wednesday', {lng: "es"})].map(
                  (item: any, i: number) => {
                    return (
                      <p key={i} style={{ color: "gray", margin: 0 }}>
                        {item.star}:00 {i18n.t('reservas:to')} {item.end}:00
                      </p>
                    );
                  }
                )}
              </div>
            )}
            <div>
              <Button
                type="primary"
                onClick={() =>
                  onOpenModal(
                    i18n.t('reservas:wednesday'),
                    user.reservationScheduled[i18n.t('reservas:wednesday', {lng: "es"})]
                  )
                }
              >
                {i18n.t('reservas:editWednesday')}
              </Button>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: 15 }}>
          <h3>{i18n.t('reservas:thursday')}</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {user.reservationScheduled[i18n.t('reservas:thursday', {lng: "es"})].length === 0 ? (
              <Tag color="red">{i18n.t('reservas:closed')}</Tag>
            ) : (
              <div>
                {user.reservationScheduled[i18n.t('reservas:thursday', {lng: "es"})].map(
                  (item: any, i: number) => {
                    return (
                      <p key={i} style={{ color: "gray", margin: 0 }}>
                        {item.star}:00 {i18n.t('reservas:to')} {item.end}:00
                      </p>
                    );
                  }
                )}
              </div>
            )}
            <div>
              <Button
                type="primary"
                onClick={() =>
                  onOpenModal(i18n.t('reservas:thursday'), user.reservationScheduled[i18n.t('reservas:thursday', {lng: "es"})])
                }
              >
                {i18n.t('reservas:editThursday')}
              </Button>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: 15 }}>
          <h3>{i18n.t('reservas:friday')}</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {user.reservationScheduled[i18n.t('reservas:friday', {lng: "es"})].length === 0 ? (
              <Tag color="red">{i18n.t('reservas:closed')}</Tag>
            ) : (
              <div>
                {user.reservationScheduled[i18n.t('reservas:friday', {lng: "es"})].map(
                  (item: any, i: number) => {
                    return (
                      <p key={i} style={{ color: "gray", margin: 0 }}>
                        {item.star}:00 {i18n.t('reservas:to')} {item.end}:00
                      </p>
                    );
                  }
                )}
              </div>
            )}
            <div>
              <Button
                type="primary"
                onClick={() =>
                  onOpenModal(i18n.t('reservas:friday'), user.reservationScheduled[i18n.t('reservas:friday', {lng: "es"})])
                }
              >
                {i18n.t('reservas:editFriday')}
              </Button>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: 15 }}>
          <h3>{i18n.t('reservas:saturday')}</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {user.reservationScheduled[i18n.t('reservas:saturday', {lng: "es"})].length === 0 ? (
              <Tag color="red">{i18n.t('reservas:closed')}</Tag>
            ) : (
              <div>
                {user.reservationScheduled[i18n.t('reservas:saturday', {lng: "es"})].map(
                  (item: any, i: number) => {
                    return (
                      <p key={i} style={{ color: "gray", margin: 0 }}>
                        {item.star}:00 {i18n.t('reservas:to')} {item.end}:00
                      </p>
                    );
                  }
                )}
              </div>
            )}
            <div>
              <Button
                type="primary"
                onClick={() =>
                  onOpenModal(i18n.t('reservas:saturday'), user.reservationScheduled[i18n.t('reservas:saturday', {lng: "es"})])
                }
              >
                {i18n.t('reservas:editSaturday')}
              </Button>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: 15 }}>
          <h3>{i18n.t('reservas:sunday')}</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {user.reservationScheduled[i18n.t('reservas:sunday', {lng: "es"})].length === 0 ? (
              <Tag color="red">{i18n.t('reservas:closed')}</Tag>
            ) : (
              <div>
                {user.reservationScheduled[i18n.t('reservas:sunday', {lng: "es"})].map(
                  (item: any, i: number) => {
                    return (
                      <p key={i} style={{ color: "gray", margin: 0 }}>
                        {item.star}:00 {i18n.t('reservas:to')} {item.end}:00
                      </p>
                    );
                  }
                )}
              </div>
            )}
            <div>
              <Button
                type="primary"
                onClick={() =>
                  onOpenModal(i18n.t('reservas:sunday'), user.reservationScheduled[i18n.t('reservas:sunday', {lng: "es"})])
                }
              >
                {i18n.t('reservas:editSunday')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {hour ? (
        <EditHour
          visibleEdit={visibleEdit}
          title={title}
          setvisibleEdit={setvisibleEdit}
          hours={hour}
          setHours={setHours}
          user={user}
          refetch={refetch}
        />
      ) : null}
    </Modal>
  );
}
