import { useState } from "react";
import "./add.css";
import {
  Tooltip,
  Upload,
  Input,
  Switch,
  message,
  Button,
} from "antd";
import {
  PlusCircleOutlined,
  LoadingOutlined,
  InstagramOutlined,
  FacebookOutlined,
  TwitterOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { useMutation } from "react-apollo";
import { mutations } from "../../GraphQL";
import Horario from "./FormHorario";
import i18n from '../../i18n';
import { PRIMARY_COLOR } from "../../Utils/Urls";

const { TextArea } = Input;

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default function EditPartner(props: any) {
  const { refetch, data } = props;

  const [imagen, setimagen] = useState(data.image);
  const [logo, setiLogo] = useState(data.logo);
  const [categoryID, setCategoryID] = useState(data.categoryID);
  const [categoryName, setCategoryName] = useState(data.categoryName);
  const [tipo, setTipo] = useState(data.tipo);
  const [loadingImage, setloadingImage] = useState(false);
  const [loadinglogo, setloadinglogo] = useState(false);
  const [city, setCity] = useState(data.city);
  const [lat, setlat] = useState(data.adress.lat);
  const [lgn, setlgn] = useState(data.adress.lgn);
  const [calle, setCalle] = useState(data.adress.calle);
  const [cp, setCp] = useState(data.adress.codigoPostal);

  const [title, setTitle] = useState(data.title);
  const [description, setDescription] = useState(data.description);
  const [shipping, setShipping] = useState(data.shipping);
  const [shippingLow, setShippingLow] = useState(data.previous_shipping);
  const [service, setService] = useState(data.extras);
  const [minime, setMinime] = useState(data.minime);
  const [email, setEmail] = useState(data.email);
  const [phone, setPhone] = useState(data.phone);
  const [stimated, setStimated] = useState(data.stimateTime);
  const [slug, setSlug] = useState(data.slug);
  const [tagOffert, setTagOffert] = useState(data.tagOffert);
  const [tipe, setTipe] = useState(data.type);
  const [isParnet, setisParnet] = useState(data.ispartners);
  const [isnew, setIsnew] = useState(data.isnew);
  const [open, setOpen] = useState(data.open);
  const [takeAway, setTakeawai] = useState(data.llevar);
  const [delivery, setDelivery] = useState(data.delivery);
  const [autoshipping, setAutoshipping] = useState(data.autoshipping);
  const [offert, setOffert] = useState(data.inOffert);
  const [cashPayment, setCashPayment] = useState(data.cashPayment);
  const [otter, setOtter] = useState(data.isOtterPartner);
  const [highkitchen, setHighkitchen] = useState(data.highkitchen);
  const [deliverect, setdeliverect] = useState(data.isDeliverectPartner);
  const [ordatic, setOrdatic] = useState(data.isOrdaticPartner);
  const [noScheduled, setNoscheduled] = useState(data.noScheduled);
  const [scheduleOnly, setScheduleOnly] = useState(data.scheduleOnly.available);
  const [scheduleOnlyHour, setScheduleOnlyHour] = useState(
    data.scheduleOnly.hour
  );
  const [collections, setcollections] = useState(data.collections);
  const [instagram, setinstagram] = useState(data.socialLink.instagram);
  const [facebook, setfacebook] = useState(data.socialLink.facebook);
  const [twitter, settwitter] = useState(data.socialLink.twitter);
  const [web, setweb] = useState(data.socialLink.web);
  const [numero, setNumero] = useState(data.adress.numero);
  const [Alergenos, setAlergenos] = useState(data.alegeno_url);
  const [forttalezaURL, setforttalezaURL] = useState(data.forttalezaURL);
  const [contactCode, setcontactCode] = useState(data.contactCode);

  const [singleUploadToStoreImagenAws] = useMutation(
    mutations.UPLOAD_FILE_STORE
  );
  const domain = localStorage.getItem("domain");

  const [actualizarRestaurant] = useMutation(mutations.ACTUALIZAR_STORE);
  const [actualizarRestaurantAdmin] = useMutation(mutations.UPDATE_STORE);

  const input = {
    _id: data._id,
    title: title,
    image: imagen,
    description: description,
    shipping: shipping,
    extras: service,
    minime: minime,
    rating:data.rating,
    diaslaborales: data.diaslaborales,
    categoryName: categoryName,
    categoryID: categoryID,
    phone: phone,
    email: email,
    logo: logo,
    type: tipe,
    tipo: tipo,
    ispartners: isParnet,
    isnew: isnew,
    llevar: takeAway,
    delivery: delivery,
    alegeno_url: Alergenos,
    autoshipping: autoshipping,
    open: open,
    previous_shipping: shippingLow,
    stimateTime: stimated,
    slug: slug,
    highkitchen: highkitchen,
    inOffert: offert,
    includeCity: [city],
    OnesignalID: data.OnesignalID,
    channelLinkId: data.channelLinkId,
    collections: collections,
    isDeliverectPartner: deliverect,
    salvingPack: data.salvingPack,
    isOrdaticPartner: ordatic,
    isOrdaticPartnerID: data.isOrdaticPartnerID,
    adress: {
      calle: calle,
      numero: numero,
      codigoPostal: cp,
      ciudad: city,
      lat: lat,
      lgn: lgn,
    },
    city: city,
    contactCode: contactCode,
    socialLink: {
      instagram: instagram,
      facebook: facebook,
      twitter: twitter,
      web: web,
    },
    isOtterPartner: otter,
    OtterPartnerId: data.OtterPartnerId,
    noScheduled: noScheduled,
    scheduleOnly: {
      available: scheduleOnly,
      hour: scheduleOnlyHour,
    },
    shorting: data.shorting,
    cashPayment: cashPayment,
    tagOffert: tagOffert ? tagOffert : null,
    forttalezaURL: forttalezaURL,
    domain: domain,
    language: i18n.language,
  };

  const createdStore = () => {
    actualizarRestaurantAdmin({ variables: { input: { data: input } } })
      .then((res: any) => {
        const result = res.data.actualizarRestaurantAdmin;
        if (result.success) {
          message.success(result.messages);
          refetch();
        } else {
          message.warning(result.messages);
        }
      })
      .catch(() => {
        message.error(i18n.t('store:somethingWentWrong'));
      });
  };
  const uploadButton = (
    <Tooltip title={i18n.t('store:addCoverFoto')}>
      <div
        style={{
          width: 450,
          height: 200,
          borderRadius: 10,
          alignItems: "center",
          justifyContent: "center",
          padding: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loadingImage ? (
          <LoadingOutlined style={{ fontSize: 30, color: PRIMARY_COLOR }} />
        ) : (
          <PlusCircleOutlined style={{ fontSize: 30, color: PRIMARY_COLOR }} />
        )}

        <div className="ant-upload-text">
          <span style={{ fontSize: 12 }}>
            {i18n.t('store:addSpecifiedCoverFoto')}
          </span>
        </div>
      </div>
    </Tooltip>
  );

  const uploadButtonAvatar = (
    <Tooltip title={i18n.t('store:addStoreLogo')}>
      <div
        style={{
          width: 100,
          height: 100,
          borderRadius: 10,
          alignItems: "center",
          justifyContent: "center",
          padding: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loadinglogo ? (
          <LoadingOutlined style={{ fontSize: 30, color: PRIMARY_COLOR }} />
        ) : (
          <PlusCircleOutlined style={{ fontSize: 30, color: PRIMARY_COLOR }} />
        )}

        <div className="ant-upload-text">
          <span style={{ fontSize: 12 }}>{i18n.t('store:addSpecifiedStoreLogo')}</span>
        </div>
      </div>
    </Tooltip>
  );

  return (
    <div className="cont_store">
      <div style={{ minHeight: 700, padding: 20 }}>
        <div>
          <div
            style={{
              width: "100%",
              height: 300,
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 30,
            }}
          >
            <Upload
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={async (data) => {
                setloadingImage(true);
                let file = await getBase64(data.file);
                singleUploadToStoreImagenAws({
                  variables: { file, domain: domain, language: i18n.language},
                })
                  .then((res: any) => {
                    setloadingImage(false);
                    setimagen(
                      res.data.singleUploadToStoreImagenAws.data.Location
                    );
                  })
                  .catch((error: any) => {
                    setloadingImage(false);
                    message.error(
                      i18n.t('store:imageTooLarge')
                    );
                  });
              }}
            >
              {imagen ? (
                <Tooltip title={i18n.t('store:clickToChange')}>
                  <img className="imagen_prod_add" alt="" src={imagen} />
                </Tooltip>
              ) : null}

              {!imagen ? uploadButton : null}
            </Upload>
          </div>

          <div
            style={{
              width: 100,
              height: 100,
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 140,
            }}
          >
            <Upload
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={async (data) => {
                setloadinglogo(true);
                let file = await getBase64(data.file);

                singleUploadToStoreImagenAws({ variables: { file } })
                  .then((res: any) => {
                    setloadinglogo(false);
                    setiLogo(
                      res.data.singleUploadToStoreImagenAws.data.Location
                    );
                  })
                  .catch((error: any) => {
                    setloadinglogo(false);
                    message.error(
                      i18n.t('store:imageTooLarge')
                    );
                  });
              }}
            >
              {logo ? (
                <Tooltip title={i18n.t('store:clickToChange')}>
                  <img className="imagen_prod_add_logo" alt="" src={logo} />
                </Tooltip>
              ) : null}

              {!logo ? uploadButtonAvatar : null}
            </Upload>
          </div>
        </div>

        <div
          style={{
            textAlign: "center",
            marginTop: 30,
            marginBottom: 20,
          }}
        >
          <Button
            type="primary"
            href="https://www.iloveimg.com/es/comprimir-imagen"
            target="_blank"
          >
            {i18n.t('store:compressImage')}
          </Button>
        </div>

        <div className="Forma_cont">
          <div className="Forma" style={{ marginTop: 30 }}>
            <h1>{i18n.t('store:storeInfo')}</h1>
            <Input
              placeholder={i18n.t('store:nameStore')}
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
            <TextArea
              placeholder={i18n.t('store:description')}
              rows={4}
              style={{ marginTop: 15, height: 120 }}
              value={description[i18n.language]}
              showCount
              maxLength={150}
              onChange={(e) => {
                setDescription({
                  ...description,
                  [i18n.language]: e.target.value, // Actualiza solo el idioma actual
                })
              }}
            />

            <div className="boolean_content">
              <div>
                <Switch
                  checkedChildren={i18n.t('store:delivery')}
                  unCheckedChildren={i18n.t('store:notDelivery')}
                  defaultChecked={delivery}
                  style={{ marginTop: 20 }}
                  onChange={(checked) => setDelivery(checked)}
                />
              </div>
              <div>
                <Switch
                  checkedChildren={i18n.t('store:takeAway')}
                  unCheckedChildren={i18n.t('store:notTakeAway')}
                  defaultChecked={takeAway}
                  style={{ marginTop: 20 }}
                  onChange={(checked) => setTakeawai(checked)}
                />
              </div>
            </div>

            <div style={{ marginTop: 20 }}>
              <h3>{i18n.t('store:addressStore')}</h3>

              <Input
                placeholder="Dirección del establecimiento"
                onChange={(e) => setCalle(e.target.value)}
                style={{ width: "100%", marginTop: 30 }}
                value={calle}
                disabled={true}
              />
              <div>
                <div>
                  <Input
                    placeholder={i18n.t('store:number')}
                    onChange={(e) => setNumero(e.target.value)}
                    style={{ marginTop: 15, width: "100%" }}
                    value={numero}
                    disabled={true}
                  />
                  <Input
                    placeholder={i18n.t('store:zipCode')}
                    value={cp}
                    onChange={(e) => setCp(e.target.value)}
                    style={{ marginTop: 15, width: "100%" }}
                    disabled={true}
                  />
                  <Input
                    placeholder={i18n.t('store:city')}
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                    style={{ marginTop: 20, width: "100%" }}
                    disabled={true}
                  />
                </div>
              </div>

              <div
                style={{
                  marginTop: 10,
                }}
              >
                <Input
                  value={contactCode}
                  placeholder={i18n.t('store:ownerId')}
                  onChange={(e) => setcontactCode(`${e.target.value}`)}
                  style={{ width: "100%", marginTop: 15 }}
                  disabled={true}
                />
              </div>
            </div>

            <div style={{ marginTop: 30, flexDirection: "row" }}>
              <Input
                value={instagram}
                prefix={<InstagramOutlined />}
                placeholder={i18n.t('store:instagramUrl')}
                style={{ marginBottom: 15, width: "50%" }}
                onChange={(e) => setinstagram(e.target.value)}
              />
              <Input
                value={facebook}
                prefix={<FacebookOutlined />}
                placeholder={i18n.t('store:facebookUrl')}
                style={{ marginBottom: 15, width: "50%" }}
                onChange={(e) => setfacebook(e.target.value)}
              />
              <Input
                value={twitter}
                prefix={<TwitterOutlined />}
                placeholder={i18n.t('store:twitterUrl')}
                style={{ marginBottom: 15, width: "50%" }}
                onChange={(e) => settwitter(e.target.value)}
              />
              <Input
                value={web}
                prefix={<GlobalOutlined />}
                placeholder={i18n.t('store:webUrl')}
                onChange={(e) => setweb(e.target.value)}
                style={{ marginBottom: 15, width: "50%" }}
              />

              <Input
                value={Alergenos}
                placeholder={i18n.t('store:allergenUrl')}
                onChange={(e) => setAlergenos(`${e.target.value}`)}
                style={{ marginBottom: 15, width: "100%" }}
              />
            </div>
            <Button
              onClick={createdStore}
              style={{
                width: "100%",
                height: 50,
                borderRadius: 100,
                marginTop: 30,
              }}
              type="primary"
            >
              {i18n.t('store:updateInfo')}
            </Button>
          </div>

          <div className="horario">
            <h1>
              <Horario
                data={data}
                actualizarRestaurant={actualizarRestaurant}
                refetch={refetch}
              />
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
