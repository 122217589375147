import React, { useState } from "react";
import "./index.css";
import { message, Modal, Input, InputNumber, Button , Upload, Tooltip, Switch } from "antd";
import { mutations, query } from "../../../GraphQL";
import { useMutation, useQuery, Mutation } from "react-apollo";
import AddProduct from "../../AddProduct/add";
import { formaterPrice } from "../../../Utils/FormaterPrice";
import i18n from "../../../i18n";
import { PlusCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { PRIMARY_COLOR } from "../../../Utils/Urls";
import { DeleteOutlined } from "@ant-design/icons";

export default function EdditBundled(props) {
  const { refetch, datas, visible, setVisible, setdataDetails, bundled } = props;
  const [title, settitle] = useState(datas.name[i18n.language]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [max, setmax] = useState(datas.max);
  const [min, setmin] = useState(datas.min);
  const [mulmax, setmulmax] = useState(datas.multiMax);
  const [mulmin, setmulmin] = useState(datas.multiply);
  const [products, setproducts] = useState(datas.subProducts);
  const domain = localStorage.getItem("domain");
  const [actualizarBundled] = useMutation(mutations.ACTUALIZAR_BUNDLED);
  const [loadingImage, setloadingImage] = useState(false);
  const [imagen, setimagen] = useState(datas.imageUrl);
  const [snoozed, setsnoozed] = useState(datas.snoozed);
  const [recomended, setrecomended] = useState(datas.recomended);
  const [price, setprice] = useState(0);



  function onChangeRecomended(checked) {
    setrecomended(checked);
  }

  function onChangeSnoozed(checked) {
    setsnoozed(checked);
  }



  const response = useQuery(query.GET_PRODUCT_BUNDLED, {
    variables: {
      store: localStorage.getItem("id"),
      domain: domain,
      language: i18n.language,
    },
  });


  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }


  const uploadButton = (
    <Tooltip title={i18n.t("addProduct:addPhoto")}>
      <div>
        {loadingImage ? (
          <LoadingOutlined style={{ fontSize: 30, color: PRIMARY_COLOR }} />
        ) : (
          <PlusCircleOutlined style={{ fontSize: 30, color: PRIMARY_COLOR }} />
        )}

        <div className="ant-upload-text">
          <p>{i18n.t("addProduct:addPhoto")}</p>
        </div>
      </div>
    </Tooltip>
  );


  const bundleds =
    response && response.data && response.data.getNewProductoBundled
      ? response.data.getNewProductoBundled.data
      : [];

  if (response) {
    response && response.refetch();
  }

  const deletedItem = (ids) => {
    var i = products.findIndex((x) => x === ids);
    if (i !== -1) {
      products.splice(i, 1);
      setproducts(products.concat());
    }
  };

  const SelectProduct = (ids) => {
    setproducts(products.concat(ids));
  };

  const input = {
    _id: datas._id,
    name: title,
    description: datas.description[i18n.language],
    account: datas.account,
    location: datas.location,
    productType: datas.productType,
    plu: datas.plu,
    price: datas.price,  //distinto en modifield
    sortOrder: datas.sortOrder,
    deliveryTax: datas.deliveryTax,
    takeawayTax: datas.takeawayTax,
    multiply: mulmin,
    multiMax: mulmax,
    posProductId: datas.posProductId,
    posProductCategoryId: datas.posProductCategoryId,
    subProducts: products, //Dinstinto de modifield
    productTags: datas.productTags,
    posCategoryIds: datas.posCategoryIds,
    imageUrl: null, //distinto en modifield imagen??
    max: max,
    min: min,
    capacityUsages: datas.capacityUsages,
    parentId: datas.parentId,
    visible: datas.visible, //distinto en modifield
    snoozed: datas.snoozed, //distinto en modifield
    subProductSortOrder: [],
    recomended: datas.recomended, //distinto en modifield
    quantity: datas.quantity, 
    new: datas.new, //distinto en modifield
    popular: datas.popular, //distinto en modifield
    offert: datas.offert, //distinto en modifield
    previous_price: datas.previous_price, //No existe en modifield
    storeId: datas.storeId, 
  };

  const handleOk = () => {
    setConfirmLoading(true);
    actualizarBundled({
      variables: {
        input: { data: input, language: i18n.language, domain: domain },
      },
    })
      .then((res) => {
        if (res.data.actualizarBundled.success) {
          refetch();
          setVisible(false);
          setdataDetails(null);
          setConfirmLoading(false);
          message.success(i18n.t("addProduct:productSuccessfullyUpdated"));
        } else {
          message.warning(i18n.t("addProduct:somethingWentWrong"));
          setConfirmLoading(false);
          refetch();
        }
      })
      .catch(() => {
        refetch();
        message.error(i18n.t("addProduct:somethingWentWrong"));
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    setVisible(false);
    setdataDetails(null);
  };

  function onChangeMin(value) {
    //* Primero
    setmin(value);
  }

  function onChangeMax(value) {
    //* Segundo
    setmax(value);
  }


  function onChangemulMin(value) {
    //* Tercero
    setmulmin(value);
  }
  
  function onChangemulMax(value) {
    //* Cuarto
    setmulmax(value);
  }


  const isOk = () => {
    if (title) {
      return false;
    } else {
      return true;
    }
  };

  const onchangeTitle = (e) => {
    e.preventDefault();
    settitle(e.target.value);
  };

  return (
    <Modal
    title={i18n.t("addProduct:editOptions")}
    open={visible}
    onOk={handleOk}
    okText={i18n.t("addProduct:saveChanges")}
    cancelText={i18n.t("addProduct:cancel")}
    confirmLoading={confirmLoading}
    onCancel={handleCancel}
    okButtonProps={{ disabled: isOk() }}
  >
    <div className="content_modal">
      <div className="headder">
        
      </div>

      <div className="add_info">
        <h4>{i18n.t("addProduct:mandatoryOptions")}</h4>
        <p>
          {i18n.t("addProduct:mandatoryOptions")}{" "}
          <span style={{ color: "red" }}>*</span>
        </p>
        <Input
          placeholder={i18n.t("addProduct:productName")}
          onChange={onchangeTitle}
          defaultValue={title}
        />

       

        <div className="btn_contents">
          <div>
            <p>{i18n.t("addProduct:minimum")}</p>
            <InputNumber
              min={0}
              max={100}
              style={{ width: "80%" }}
              placeholder={i18n.t("addProduct:minimum")}
              value={min}
              type="number"
              onChange={onChangeMin}
            />
          </div>
          <div>
            <p>{i18n.t("addProduct:maximum")}</p>
            <InputNumber
              min={1}
              max={100}
              style={{ width: "51%" }}
              placeholder={i18n.t("addProduct:maximum")}
              value={max}
              type="number"
              onChange={onChangeMax}
            />
          </div>
        </div>

        <div className="btn_contents">
          <div>
            <p>{i18n.t("addProduct:minimumMultiplicable")}</p>
            <InputNumber
              min={0}
              max={100}
              style={{ width: "49%" }}
              placeholder={i18n.t("addProduct:minimumMultiplicable")}
              value={mulmin}
              type="number"
              onChange={onChangemulMin}
            />
          </div>
          <div>
            <p>{i18n.t("addProduct:maximumMultiplicable")}</p>
            <InputNumber
              min={1}
              max={100}
              style={{ width: "51%" }}
              placeholder={i18n.t("addProduct:maximumMultiplicable")}
              value={mulmax}
              type="number"
              onChange={onChangemulMax}
            />
          </div>
        </div>

      
      </div>
      <div style={{ marginTop: 20 }}>
          <h3>{i18n.t("addProduct:options")}</h3>
        </div>

        <AddProduct
          store={localStorage.getItem("id")}
          refetch={response.refetch}
          bundled={true}
        />

      <div>
          {bundleds &&
            bundleds.map((item, i) => {
              const adds =
                products && products.filter((x) => x === item._id).length > 0;

              return (
                <Button
                  onClick={() =>
                    adds ? deletedItem(item._id) : SelectProduct(item._id)
                  }
                  type={adds ? "primary" : "dashed"}
                  key={i}
                  style={{ marginRight: 10, marginTop: 10 }}
                >
                  {item.name[i18n.language]} (× {item.multiMax}){" "}
                  {item.price > 0
                    ? `  +${formaterPrice(item.price / 100, "", "")}`
                    : null}
                </Button>
              );
            })}
        </div>
    </div>
  </Modal>
  );
}
