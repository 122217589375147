import React, { useEffect, useState } from "react";
import Card from "./Card";
import "./index.css";
import { DatePicker, Button, Input, Select } from "antd";
import moment from "moment";
import { useQuery } from "react-apollo";
import { query } from "../../GraphQL";
//@ts-ignore
import locate from "moment/locale/es";
import Report from "./Report";

import i18n from "../../i18n";

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "MM/DD/YYYY";

// const date = new Date();
// let day = date.getDate();
// let month = date.getMonth() + 1;
// let year = date.getFullYear();
//const fromDate = `${month}/${day}/${year}`;
const date = moment();
date.format(dateFormat)
date.subtract(15,"days")
const fromDate = date

const date2 = moment();
date2.format(dateFormat)
date2.add(15,"days")
const toDate = date2

const todaydate = moment();
todaydate.format(dateFormat)
// let day1 = date.getDate();
// let month1 = date.getMonth() + 2;
// let year1 = date.getFullYear();

// const isMonth31 = [1, 3, 5, 7, 8, 10, 12].includes(month1);
// const toDate = `${month1}/${
//   Number(day1) === 31 && !isMonth31 ? day1 - 1 : day1
// }/${year1}`;




export default function Order({ user }) {
  const [isModalReportVisible, setIsModalReportVisible] = useState(false);
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [dateRange, setDateRange] = useState(null);
  const [tipo, setTipo] = useState("Periodo");
  const [search, setSearch] = useState(null);
  const domain = localStorage.getItem("domain");

  const {
    data = {},
    loading,
    refetch,
    error,
  } = useQuery(query.GET_ORDER, {
    variables: {
      domain: domain,
      language: i18n.language,
      page: page,
      limit: limit,
      search: search,
      dateRange: {
        fromDate: dateRange ? dateRange.fromDate : fromDate,
        toDate: dateRange ? dateRange.toDate : toDate,
      },
      status: [
        "Pendiente de pago",
        "Enviada",
        "Nueva",
        "Confirmada",
        "Listo para recoger",
        "En la cocina",
        "Por recoger",
        "Preparando para el envío",
        "En camino",
        "Recogida",
        "Fallida",
        "Cancelada",
        "Devuelta",
        "Devolviendo",
        "Entregada",
        "Rechazado",
        "Devuelto",
        "Finalizada",
        "Pendiente de pago",
        "Enviada",
        "Recogida",
        "Fallida",
        "Cancelada",
        "Devuelta",
        "Devolviendo",
        "Rechazada por la tienda",
        "Rechazada por el rider",
        "Resolución",
      ],
    },
    pollInterval: 20000,
  });

  const { getNewOrderStore } = data;

  const orders = getNewOrderStore ? getNewOrderStore.data : [];

  const documents = getNewOrderStore ? getNewOrderStore.count : 0;

  function handleChangeTipo(value) {
    setTipo(value);
    
   // const fromToday = `${month}/${day}/${year}`;

    setDateRange({
      fromDate:
        value === "Sólo un día" ? moment(todaydate).format(dateFormat) : fromDate,
      toDate:
        value === "Sólo un día" ? moment(todaydate).format(dateFormat) : toDate,
    });
    setpage(1);
  }

  const onDateRangeChange = (dates, dateStrings) => {
    const fromDate = dateStrings[0];
    const toDate = dateStrings[1];
    setDateRange({
      fromDate:
        tipo === "Sólo un día" ? moment(todaydate).format(dateFormat) : fromDate,
      toDate:
        tipo === "Sólo un día" ? moment(todaydate).format(dateFormat) : toDate,
    });
  };

  const onSearch = (search) => {
    if (search.startsWith("#")) {
      const [almoadilla, ...rest] = search.split("#");
      search = rest;
    }
    setSearch(String(search));
    setpage(1);
  };

  const onChangePage = (page, pageSize) => {
    setpage(page);
    setlimit(pageSize);
  };

  return (
    <div>
      <div>
        <div className="search_container">
          <div>
            <h3>{i18n.t("orders:filterByDateOrId")}</h3>
            <Select
              value={tipo}
              style={{
                width: "auto",
                height: 35,
                marginRight: 10,
              }}
              placeholder="Elige un tipo"
              onChange={handleChangeTipo}
            >
              <Option value="Periodo">{i18n.t("orders:period")}</Option>
              <Option value="Sólo un día">{i18n.t("orders:onlyOneDay")}</Option>
            </Select>
            {tipo === "Periodo" ? (
              <RangePicker
                locale={locate}
                defaultValue={[
                  moment(fromDate, dateFormat),
                  moment(toDate, dateFormat),
                ]}
                format={dateFormat}
                onChange={onDateRangeChange}
                style={{
                  height: 35,
                  marginRight: 10,
                  marginBottom: 15,
                }}
              />
            ) : (
              <DatePicker
                onChange={onDateRangeChange}
                style={{
                  height: 35,
                  marginRight: 10,
                  marginBottom: 15,
                }}
                defaultValue={moment(todaydate, todaydate)}
                format={dateFormat}
                locale={locate}
              />
            )}

            {/*  <Button
              onClick={() => setIsModalReportVisible(true)}
              style={{
                marginTop: 15,
                height: 40,
                width: 363,
              }}
              type="primary"
            >
              OBTENER REPORTE
            </Button> */}
          </div>
          <div>
            <Search
              style={{ width: "100%", minWidth: 363, marginTop: 15 }}
              placeholder={i18n.t("orders:searchOrderById")}
              allowClear
              type="text"
              enterButton={i18n.t("orders:search")}
              size="large"
              onSearch={onSearch}
              maxLength={10}
            />
          </div>
        </div>
        <Card
          loading={loading}
          datos={orders}
          setpage={setpage}
          setlimit={setlimit}
          refetch={refetch}
          onChangePage={onChangePage}
          limit={limit}
          documents={documents}
        />
      </div>
      <Report
        setIsModalVisible={setIsModalReportVisible}
        isModalVisible={isModalReportVisible}
        user={user}
      />
    </div>
  );
}
